<template>
	<div>
		<PageBanner
			v-if="hasBanner"
			:title="bannerTitle"
			:description="pageDsrcptn"
			:backgroundImg="pageImg"
			:itemSetId="itemSetId"
		/>
		<div class="generic-container centering">
			<div class="generic-lead">
				<div class="generic-lead-text">
					<h2 class="centering">
						{{ pTitle }}
						<div class="dash"></div>
					</h2>
				</div>
			</div>
			<div v-if="pContent" class="generic-main-content centering">
				<div v-if="frTitle && frContent" class="fr-container">
					<button class="trigger-modal" style="background-color: #95b136" @click="triggerModal">FR</button>
				</div>
				<p v-html="pContent"></p>
				<p
					v-if="pTitle==='Το Βυζαντινό αρχείο'"
					class="link-container">
					<router-link
						:to="{ path: '/archives/sources'}"
						tag="a"
						class="sources-link"
					>Πηγές
						<font-awesome-icon class="sources-icon" icon="play-circle" />
					</router-link>
				</p>
				<p class="link-container">
					<text-modal ref="textModal"
						:modal-title="frTitle"
						:modal-content="frContent"
					></text-modal>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import PageBanner from "@/components/PageBanner.vue"
import TextModal from "@/components/ui/TextModal.vue"

export default {
	name: "GenericPage",
	components: {
		PageBanner,
		TextModal
	},
	props: {
		mainBanner: {
			type: String,
			required: false,
			default() {
				return ""
			}
		},
		pTitle: {
			type: String,
			required: false
		},
		bannerTitle: {
			type: String,
			required: false
		},
		pDescription: {
			type: String,
			required: false
		},
		pContent: {
			type: String,
			required: false,
			default() {
				return ""
			}
		},
		hasBanner: {
			type: Boolean,
			required: false,
			default() {
				return true
			}
		},
		itemSetId: {
			type: Number,
			required: false,
			default() {
				return 0
			}
		}
	},
	data() {
		return {
			pageTitle: this.pTitle,
			pageDsrcptn: this.pDescription,
			pageImg: this.mainBanner,
			frTitle: "",
			frContent: ""

		}
	},
	created() {
		this.pageImg = this.mainBanner
		this.pageTitle = this.pTitle
		this.pageDsrcptn = this.pDescription
	},
	methods: {
		getFrench() {
			if (this.$route.params.contentPage > 0) {
				let contentUrl = `${this.$store.state.rootUrl2}pages/${this.$route.params.contentPage}`
				this.$http.get(contentUrl)
					.then((res) => {
						this.frTitle = res.data.page.fr_title
						this.frContent = res.data.page.fr_content
					})
			}
		},
		triggerModal() {
			this.$refs.textModal.modalShow = true
		}
	},
	mounted() {
		this.getFrench()
	}
}
</script>

<style lang="scss" scoped>
	.generic-container {
		padding-top: 5%;
	}
	.generic-lead {
		justify-content: center;
		align-items: center;
		width: 100%;
		display: flex;
		min-height: 2vh;
	}

	.fr-container {
		width: 100%;
    text-align: right;

	}

	.generic-lead-text {
		width: 100%;
		height: 100%;
		padding: 0 5%;

		h2 {
			font-size: 50px;
			font-weight: 100;
			color: #000;
			text-align: center;
			margin-top: -2%;

			.dash {
				width: 25%;
				margin: 1% 0;
			}
		}
	}
	.generic-main-content {
		padding: 1% 20% 5% 20%;

		p {
			text-align: justify;
			font-size: 1rem;
			line-height: 1.8;
		}
	}

	.trigger-modal {
			background-color: #95b136;
			border: none;
			color: #fff;
			border-radius: 5px;
			-webkit-appearance: none;
			-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
	}

	.link-container {
		width: 100%;
		display: flex;
    justify-content: space-between;
	}

	.sources-link {
	color: #4a522d!important;
	text-align: left;
	cursor: pointer;
	font-size: 1.3rem!important;
	font-weight: 700!important;
	width: 45%;

	&:hover {
		text-shadow: 1px 0px $tur-blue;
	}

	.sources-icon {
		font-size: 20px;
		margin-top: -5px;
    margin-left: 5px;
	}
}

	@media(max-width: 1200px) {

		.generic-main-content {
			padding: 5% 15%;
		}

	}

	@media (max-width: 996px) {
		.generic-lead {
			flex-direction: column-reverse;
			flex-wrap: wrap;

			.generic-lead-photo {
				width: 100%;
				height: 33vw;
				margin-top: 5%;

				.inner {
					background-image: url('../assets/images/photo_foundation.jpg');
				}
			}

			.generic-lead-text {
				width: 100%;
				padding: 0 10%;

				h2 {
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-start;
					.dash {
						margin: 2% 0;
						width: 15%;
					}
				}

				p {
					width: 100%;
					max-width: 100%;
					font-size: 19px;
				}
			}
		}

		.generic-main-content {
			padding: 5% 10%;

			p {
			font-size: 14px;
			}
		}
	}

	@media(max-width: 670px) {
		.generic-lead-text h2 {
			font-size: 40px;
		}
	}
</style>
