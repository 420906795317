import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Modifiers from '@/services/modifiers.js'
import singleArchive from './modules/singleArchive.js'
import singlePage from './modules/singlePage.js'
import search from './modules/search.js'
import audioInterview from './modules/audioInterview.js'

// import { cpus } from 'os'
// import { stat } from 'fs'

// CHANGE with: http://laskaridisfoundationarchives.org

const mainUrl = "https://laskaridisfoundationarchives.org/omeka/api/"
const mainUrl2 = "https://laskaridisfoundationarchives.org/tags_admin/api/"
// const mainUrl = "http://localhost:8080/omeka/api/"
// const mainUrl2 = "http://localhost:8080/tags_admin/api/"

/*
api/item_sets?key_identity=9Vd8DRD0ZOqp22TZwEl21Oe4JHFHC1yh&key_credential=I6lu5Qm36KgLhe5OufE16Y7EN98UDpHG
*/

const URL = `${mainUrl}items?key_identity=9Vd8DRD0ZOqp22TZwEl21Oe4JHFHC1yh&key_credential=I6lu5Qm36KgLhe5OufE16Y7EN98UDpHG&`

const keys = `key_identity=9Vd8DRD0ZOqp22TZwEl21Oe4JHFHC1yh&key_credential=I6lu5Qm36KgLhe5OufE16Y7EN98UDpHG`

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		rootUrl: `${mainUrl}`,
		rootUrl2: `${mainUrl2}`,
		records: [],
		profileData: {},
		filter: {
			title: "",
			subject: "",
			tags: ""
		},
		loading: false,
		apiPage: 1,
		perPage: 12,
		archives_menu: [],
		resultsIds: [],
		subjects: [],
		tags: [],
		allItemSets: [],
		currentCollection: {},
		logoBackground: 'logo',
		logoFooter: 'logo'
	},

	actions: {
		loadData({ commit, dispatch }, payload) {
			this.state.loading = true
			// let finalUrl = URL + "item_set_id=" + payload + '&page=' + this.state.apiPage + '&per_page=' + this.state.perPage + '&sort_by=dcterms:title'
			let finalUrl = URL + "item_set_id=" + payload + '&page=' + this.state.apiPage + '&per_page=' + this.state.perPage + '&sort_by=dcterms:title'
			// let finalUrl = mainUrl2 + "search?item_set_id=" + payload + '&page=' + this.state.apiPage + '&limit=' + this.state.perPage
			axios.get(finalUrl)
				.then((response) => {
					this.state.loading = false
					let formedItems = response.data
					commit('updateRecords', formedItems)
					dispatch('setResultsIds', formedItems)
					// commit('changeLoading', false)
				})
				.catch(err => {
					this.err = err
				})
		},

		loadSubjectSuggestion({ commit }) {
			let finalUrl = mainUrl2 + 'subjects'
			axios.get(finalUrl)
				.then((response) => {
					commit('saveSubjects', response.data.subjects)
				})
		},

		loadTagSuggestions({ commit }) {
			let finalUrl = mainUrl2 + 'tags'
			axios.get(finalUrl)
				.then((response) => {
					commit('saveTags', response.data.tags)
				})
		},

		setResultsIds({ commit }, ids) {
			commit('updateIdList', ids)
		},

		clearResultsIds({ commit }) {
			commit('clearIds')
		},

		loadProfile({ commit }, id) {
			this.state.loading = true
			axios.get(`${mainUrl}items/${id}`).then((profile) => {
				this.state.loading = false
				let formedProfile = Modifiers.modifyArchiveItem(profile.data)
				commit('updateProfile', formedProfile)
			})
		},
		loadArchivesList({ commit, dispatch }) {
			axios.get(`${this.state.rootUrl}item_sets?${keys}&sort_by=dcterms:identifier&per_page=100`)
				.then((res) => {
					let menuItems = Modifiers.getParentItems(res.data)
					commit('updateMenuList', menuItems)
					let allItemSets = Modifiers.getAllSets(res.data)
					commit('updateAllSets', allItemSets)
				})
		},

		getSingleCollection({ commit }, id) {
			axios.get(`${this.state.rootUrl}item_sets?${keys}&per_page=100`)
				.then((res) => {
					let allItemSets = Modifiers.getAllSets(res.data)
					// console.log(id, allItemSets)
					commit('updateAllSets', allItemSets)
					let selection = allItemSets.filter(x => x.id === id)
					// console.log('getSingleCollection:', selection[0])
					commit('updateCurrenCollection', selection[0])
				})
		},

		setCurrenCollection({ commit, dispatch }, id) {
			const selection = this.state.allItemSets.filter(x => x.id === id)
			commit('updateCurrenCollection', selection[0])
		},

		prevApiPage({ commit, dispatch }, state) {
			if (this.state.apiPage > 1) {
				commit('goToPrevious')
				dispatch('loadData')
			} else {
				commit('goToStart')
				dispatch('loadData')
			}
		},

		nextApiPage({ commit, dispatch }) {
			commit('goToNext')
			dispatch('loadData')
		},
		updateLogoBackground({ commit }, className) {
			commit('setLogoBackground', className)
		},
		updateLogoFooter({ commit }, className) {
			commit('setLogoFooter', className)
		}
	},

	mutations: {
		updateRecords(state, newRecords) {
			state.records = newRecords.items
			search.state.resultIds = newRecords.index
			// console.log("records Updated:", state.records)
		},

		updateProfile(state, newProfile) {
			state.profileData = newProfile
		},

		updateFilter(state, value) {
			// console.log(value)
			state.filter = value
		},

		goToStart(state) {
			state.apiPage = 1
		},

		goToPrevious(state) {
			state.apiPage--
		},

		goToNext(state) {
			state.apiPage++
		},
		updateMenuList(state, newList) {
			state.archives_menu = newList
		},
		updateIdList(state, newIdList) {
			state.resultsIds = newIdList
		},
		clearIds(state) {
			state.resultsIds.splice(0, state.resultsIds.length)
		},

		saveSubjects(state, originalSubjects) {
			state.subjects = Array.from(originalSubjects, ({ value }) => value)
		},
		updateAllSets(state, newSets) {
			state.allItemSets = newSets
			// localStorage.setItem('allItemSets', JSON.stringify(newSets))
		},
		updateCurrenCollection(state, newCollection) {
			// console.log('updateCurrenCollection')
			state.currentCollection = newCollection
			// localStorage.setItem('currentCollection', JSON.stringify(newCollection))
		},

		saveTags(state, newTags) {
			state.tags = newTags
		},
		setLogoBackground(state, className) {
			state.logoBackground = className
		},
		setLogoFooter(state, className) {
			state.logoFooter = className
		}
	},

	getters: {

		getUrl(state) {
			return state.rootUrl
		},
		getUrl2(state) {
			return state.rootUrl2
		},
		getUpdateRecords(state) {
			return state.records
		},
		getCurrentIds(state) {
			return state.resultsIds
		},
		loadingStatus(state) {
			return state.loading
		},
		getAllSubjects(state) {
			return state.subjects
		},
		getGrTags(state) {
			const grTags = Array.from(state.tags, ({ tag }) => tag)
			return grTags
		},
		getFrTags(state) {
			//  Array.from(cities, ({ name}) => name);
			// eslint-disable-next-line camelcase
			const frTags = state.tags.map((tag) => {
				if (tag.tag_fr) {
					return tag.tag_fr.trim()
				}
				return ""
			})
			return frTags
		},
		getCurrentCollection(state) {
			return state.currentCollection
		},
		getSubcollections(state) {
			return state.currentCollection ? state.currentCollection.subs : []
		},
		getKeys() {
			return keys
		},
		getFormat(state) {
			return state.currentCollection.format
		},
		getLogo(state) {
			return state.logoBackground
		}
	},

	modules: {
		singleArchive,
		search,
		singlePage,
		audioInterview
	}
})
// [Α.H.G.]
