<template>
	<div class="hero">
		<div class="inner centering">
			<div class="text-container centering">
				<h1 class="main-title">Αρχεία Ιδρύματος Αικατερίνης Λασκαρίδη</h1>
				<p class="lead"></p>
			</div>
			<div class="search-container centering">
				<arch-filters
					@newSearch="newSearch"
				></arch-filters>
			</div>
		</div>
	</div>
</template>

<script>
import ArchFilters from '@/components/archivedata/ArchFilters.vue'
export default {
	name: "Hero",
	components: {
		ArchFilters
	},
	methods: {
		newSearch() {
			this.$store.dispatch('applyItemSet', { id: "", contentPage: "" })
			this.$store.dispatch('searchItems')
		}
	}
}
</script>

<style scoped lang="scss">
.hero {
	height: 80vh;

	.text-container {
		flex-grow: 1;
	}

	.inner {
		background-image: url('../assets/images/slider_home.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom;
		padding: 4rem 2rem 1rem 2rem;
		padding: 5% 5% 2.5% 5%;
		height: 100%;
  }

  h1 {
    margin-bottom: 0;
  }

	.lead {
		width: 50%;
		color: #fff;
    text-align: center;
		font-size: 18px;
		font-weight: 700;
	}
}

.search-container {
	width: 100%;
}

.main-title {
	text-shadow: 2px 2px 6px #000;
	background: transparent;
}

@media (max-width: 1200px) {
	.hero {
		height: 60vh;
	}
}

@media (max-width: 775px) {
	.main-title {
		font-size: 2rem;
		margin-bottom: -20px;
		width: 70%;
	}

	.search-container {
		padding: 0 6%;
	}
}

@media (max-width: 550px) {
	.main-title {
		font-size: 1.8rem;
	}

	.hero .lead {
		font-size: 15px;
	}

}

@media (max-width: 550px) {
	.search-container {
		padding: 1%;
	}
}

@media (max-width: 380px) {
	.main-title {
			font-size: 1.4rem;
	}
}

</style>
