<template>
	<div>
		<!-- <autocomplete :items="[ 'Apple', 'Banana', 'Orange', 'Mango', 'Pear', 'Peach', 'Grape', 'Tangerine', 'Pineapple']" />
		<template> -->
				<div class="autocomplete" :class="{'d-flex': chooseLang}">
					<div v-if="chooseLang" class="lang-container">
						<b-form-select
							class="lang-select"
							v-model="selectedLang"
							:options="langOptions"
							@change="onLangChanged"
						></b-form-select>
					</div>
					<b-form-input
						type="text"
						v-model="search"
						:placeholder="plHolder"
						@input="onChange"
						@keyup.down="onArrowDown"
						@keyup.up="onArrowUp"
						@keyup.enter="onEnter"
					></b-form-input>
					<ul id="autocomplete-results"
						v-show="isOpen"
						class="autocomplete-results"
					>
					<li class="loading" v-if="isLoading">
						Loading results...
					</li>
					<li
						v-else v-for="(result, i) in results"
						:key="i"
						@click="setResult(result)"
						class="autocomplete-result"
						:class="{ 'is-active': i === arrowCounter }"
					>
						{{ result }}
					</li>
				</ul>

			</div>

		<!-- </template> -->
	</div>
</template>

<script>
export default {
	name: "AutoComplete",
	props: {
		plHolder: {
			type: String,
			required: false,
			default: () => ""
		},
		items: {
			type: Array,
			required: false,
			default: () => []
		},
		chooseLang: {
			type: Boolean,
			required: true,
			default: false
		},
		isAsync: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	data() {
		return {
			isOpen: false,
			results: [],
			search: "",
			isLoading: false,
			arrowCounter: 0,
			langOptions: [
				{ value: 'ΕΛ', text: 'ΕΛ' },
				{ value: 'FR', text: 'FR' }
			],
			selectedLang: 'ΕΛ'
		}
	},

	methods: {
		onChange() {
			// Let's warn the parent that a change was made
			this.$emit("input", this.search)

			// Is the data given by an outside ajax request?
			if (this.isAsync) {
				this.isLoading = true
			} else {
				// Let's search our flat array
				this.filterResults()
				this.isOpen = true
			}
		},
		onLangChanged() {
			this.$emit('lang-changed', this.selectedLang)
		},
		filterResults() {
			// first uncapitalize all the things
			this.results = this.items.filter(item => {
				return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1
			})
		},
		setResult(result) {
			this.search = result
			this.onChange()
			this.isOpen = false
		},
		onArrowDown(evt) {
			if (this.arrowCounter < this.results.length) {
				this.arrowCounter = this.arrowCounter + 1
			}
		},
		onArrowUp() {
			if (this.arrowCounter > 0) {
				this.arrowCounter = this.arrowCounter - 1
			}
		},
		onEnter() {
			this.search = this.results[this.arrowCounter]
			this.isOpen = false
			this.arrowCounter = -1
			this.$emit("input", this.search)
		},
		handleClickOutside(evt) {
			if (!this.$el.contains(evt.target)) {
				this.isOpen = false
				this.arrowCounter = -1
			}
		}
	},
	watch: {
		items: function(val, oldValue) {
			// actually compare them
			if (val.length !== oldValue.length) {
				this.results = val
				this.isLoading = false
			}
		}
	},
	mounted() {
		document.addEventListener("click", this.handleClickOutside)
	},
	destroyed() {
		document.removeEventListener("click", this.handleClickOutside)
	}
}
</script>

<style lang="scss" scoped>
input, select {
	box-shadow: inset 0.5px 0.5px 1.5px 0 rgba(0, 0, 0, 0.5);
}

.autocomplete {
  position: relative;
  // width: 130px;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
	position: absolute;
	background-color: #fff;
  width: 100%;
	-webkit-box-shadow: 0px 5px 14px 0px rgba(114,114,114,1);
	-moz-box-shadow: 0px 5px 14px 0px rgba(114,114,114,1);
	box-shadow: 0px 5px 14px 0px rgba(114,114,114,1);
	z-index: 100;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
	line-height: 1.4;
  padding: 4px 2px 4px 10px;
	margin-bottom: 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: $success;
  color: white;
}

.lang-select {
	display: block;
	font-size: 16px;
	line-height: 1.3;
	padding: .6em .4em .5em .4em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	box-shadow: none;
	box-shadow: inset 0.5px 0.5px 1.5px 0 rgba(0, 0, 0, 0.5);
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI5IDE0IiBoZWlnaHQ9IjE0cHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyOSAxNCIgd2lkdGg9IjI5cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwb2x5Z29uIGZpbGw9IiMyMzFGMjAiIHBvaW50cz0iMC4xNSwwIDE0LjUsMTQuMzUgMjguODUsMCAiLz48L3N2Zz4='),
	linear-gradient(to bottom, #ffffff 0%,#fff 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .2em top 51%, 0 0;
	background-size: .5em auto, 100%;
		transform: translateX(10px);
		border-radius: 0.25rem 0% 0% 0.25rem;
	}

@-moz-document url-prefix() {
  .lang-select {
    min-width: 45px;
  }
}

	.lang-select::before {
		content: "▾";
		color: #000;
		font-size: 16px;
	}

	.lang-container {
		width: 40%;
		max-width: 45px;
		margin-left: -10px;
		box-shadow: none!important;
	}

	@media(min-width: 1450px) {

			.d-flex {
			// padding: 0 10px;
			}
	}

	.d-flex {
		display: flex;
		flex-wrap: nowrap;
		// padding: 0 10px;

		.autocomplete-results {
			margin-top: 40px;
			margin-left: 40px;
		}

		input {
			flex: 1 1 auto;
			padding-left: 20px;
		}
	}
</style>
