<template>
	<div class="home">
		<Hero />
		<ArchivesMenu />
	</div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import ArchivesMenu from '@/components/ArchivesMenu.vue'

export default {
	name: 'home',
	components: {
		Hero,
		ArchivesMenu
	}
}
</script>

<style scoped lang="scss">
	.home {
		display: block;
		clear: both;
	}
</style>
