<template>
<div class="header-container">
	<header class="the-header">
		<AppSideNavToggle @toggle="$emit('sidenavToggle')" />
		<div class="logo " :class="logoBackground" > <!-- { 'logo-blue': isSecondary } -->
			<router-link to="/"><div class="inner" /></router-link>
		</div>
		<div class="spacer" />
		<div class="navigation-items">
			<ul class="nav-list">
				<li class="nav-item">
					<router-link to="/" exact>Αρχική</router-link>
				</li>
				<li class="nav-item">
					<router-link to="/about">Ποιοι Είμαστε</router-link>
				</li>
				<li id="archives-menu" class="nav-item">
					<b-nav-item-dropdown right>
						<!-- Using 'button-content' slot -->
						<template v-slot:button-content>
							<a
								:class="{ 'router-link-active': subIsActive('/archives')}"
							>Αρχεία</a>
						</template>
						<b-dropdown-item
							v-for="link in archives"
							:key="link.id"
							:to="`/archives/${link.id}/${link.content}`"
						>
						<span class="menu-link">{{ link.title }}</span>
						</b-dropdown-item>
					</b-nav-item-dropdown>
				</li>
				<li class="nav-item">
					<router-link to="/contact">Επικοινωνία</router-link>
				</li>
			</ul>
		</div>
	</header>
</div>
</template>

<script>
import Vuex from 'vuex'
import AppSideNavToggle from "@/components/Navigation/AppSideNavToggle"

export default {
	name: "AppHeader",
	components: {
		AppSideNavToggle
	},
	data() {
		return {
			activeClass: 'router-link-exact-active router-link-active'
		}
	},
	beforeCreate() {
		this.$store.dispatch('loadArchivesList')
	},
	computed: {
		...Vuex.mapState(['logoBackground']),
		currentPage() {
			return this.$route.path
		},
		archives() {
			return this.$store.state.archives_menu
		},
		setLink() {
			if (this.$route.name === "home") {
				return 'archives'
			}
			return ''
		}
	},
	methods: {
		archiveSelected() {
			// console.log("go")
			this.$router.push({ path: "/archives" })
		},
		subIsActive(input) {
			const paths = Array.isArray(input) ? input : [input]
			return paths.some(path => {
				return this.$route.path.indexOf(path) === 0 // current path starts with this path string
			})
		},
		setLink1(id, content) {
			this.$router.replace({ path: `archives/${id}/${content}` })
			// console.log(this.$route)
			// this.$router.push({path: currentPath, query: `/${id}/${content}`})
		},
		setLogoMethod() {
			if (this.$route.name === 'archives' && this.$route.params.id === '42360') {
				this.$store.dispatch('updateLogoBackground', 'logo-blue')
			} else if (this.$route.name === 'profile' && this.$route.params.id === '42360') {
				const cookies = document.cookie.split(';').map(cookie => cookie.trim())
				const isSecondaryCookie = cookies.find(cookie => cookie.startsWith('isSecondary='))
				if (isSecondaryCookie) {
					// const isSecondary = isSecondaryCookie.split('=')[1]
					this.$store.dispatch('updateLogoBackground', 'logo-blue')
				} else {
					this.$store.dispatch('updateLogoBackground', 'logo')
				}
			} else {
				this.$store.dispatch('updateLogoBackground', 'logo')
			}
		}
	},
	mounted() {
		this.setLogoMethod()
	},
	watch: {
		'$route': 'setLogoMethod'
		/* 'profileData': {
			handler: 'setLogoMethod',
			deep: true
		} */
	}
}
</script>

<style lang="scss" scoped>

.header-container {
	height: fit-content;
	height: -moz-fit-content;
	background-color: #fff;
	padding: 0% 0 0 0;
	margin-bottom: 0%;
	max-width: 2560px;
	width: 100%;
}

.the-header {
	width: 100%;
	/* position: fixed; */
	height: 90px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 100;
	box-sizing: border-box;
	padding: 0 20px;
}

.logo {
	margin-left: 0%;
	font-size: 1.3rem;
	/* height: 3vw; */
	position: absolute;
}

.logo .inner {
	background-image: url("../../assets/images/logo.png");
	background-repeat: no-repeat;
	background-size: contain !important;
	padding: 10%;
	width: 23rem;
	z-index: 1;
	background-position-x: center;
}
.logo-blue .inner {
	background-image: url("../../assets/images/logo_archives_blue.png") !important;
}

.logo a {
	text-decoration: none;
	color: black;
}

.spacer {
	flex: 1;
}

.navigation-items {
	display: none;
}

.navigation-items li {
	text-transform: capitalize;
}
.submenu-opt {
	color: $dark;
	cursor: pointer;
	padding: 0;
}

.submenu-opt:hover {
	color: $dark;
	padding: 0;
}

.menu-link {
	white-space: normal;
}

/* @media (max-width: 1930px) {
	.header-container {
		max-width: 2560px;
	}
} */

@media(min-width: 1250px) {
	.nav-item {
	margin: 0 6%;
	}
}

@media (min-width: 768px) {
	.navigation-items {
		display: block;
		margin-right: 10%
	}
}

.nav-list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
}

.nav-item {
	margin: 0 4%;
}

.nav-item a {
	text-decoration: none;
	text-transform: capitalize;
	color: black;
	padding: 10% 1%;
}

.nav-item a:active,
.nav-item a.router-link-active {
	color: #365e65;
	text-shadow: 1px 0 0 rgba(0, 0, 0, 0.664);
	border-bottom: 2px solid $tur-blue;
}

#archives-menu a {
	border: none;
}

.nav-item a:hover {
	color: #365e65;
	text-shadow: 1px 0 0 rgba(0, 0, 0, 0.664);
}

.nav-item a:active,
.nav-item a.router-link-active {
	font-weight: 500;
}

@media (max-width: 1150px) {
	.nav-item {
		margin: 0 3%;
	}
}

@media (max-width: 767px){

	.logo{
		margin-left: 40%;
		border: none;
	}

	.logo .inner {
		width: 18rem;
		padding: 13%;
		background-position-x: right;

	}
}

@media (max-width: 600px){

	.logo{
		margin-left: 38%;
	}

	.logo .inner {
		width: 15rem;
	}
}

@media (max-width: 420px) {

	.logo{
		margin-left: 30%;
	}

	.logo .inner {
		width: 12rem;
	}
}

</style>
