import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		meta: {
			auth: true,
			title: 'Αρχική | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: Home
	},
	{
		path: '/about',
		name: 'about',
		meta: {
			auth: true,
			title: 'Ποιοι Είμαστε | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	},
	{
		path: '/search',
		name: 'search',
		meta: {
			auth: true,
			title: 'Αναζήτηση | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/SearchResults.vue')
	},
	{
		path: '/archives/:id/:contentPage',
		name: 'archives',
		meta: {
			auth: true,
			title: 'Αρχεία | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/Archives.vue')
	},
	{
		path: '/archives/collections/:id/:contentPage',
		name: 'subcollections',
		// props: {
		// 	page: `id`
		// },
		meta: {
			auth: true,
			title: 'Συλλογές | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/Subcollections.vue')
	},
	{
		path: '/archive-descriptions/:descripition',
		name: 'descripition',
		meta: {
			auth: true,
			title: 'Περιγραφή | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/ArchDescriptions.vue')
	},
	{
		path: '/biography',
		name: 'eleni-bibikou-viografiko',
		meta: {
			auth: true,
			title: 'Βιογραφία | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/Biography.vue')
	},
	{
		path: '/research',
		name: 'research',
		meta: {
			auth: true,
			title: 'Έρευνα | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/Research.vue')
	},	{
		path: '/research-papers',
		name: 'research-papers',
		meta: {
			auth: true,
			title: 'Δημοσιεύσεις | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/RsPapers.vue')
	},	{
		path: '/about-the-archive',
		name: 'about-the-archive',
		meta: {
			auth: true,
			title: 'Σχετικά με το Αρχείο | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/AboutTheArchive.vue')
	},
	{
		path: '/profile/:id',
		name: 'profile',
		meta: {
			auth: true,
			title: 'Προφίλ | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue')
	},
	{
		path: '/archives/sources',
		name: 'sources',
		meta: {
			auth: true,
			title: 'Πηγές | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/Sources.vue')
	},
	{
		path: '/contact',
		name: 'contact',
		meta: {
			auth: true,
			title: 'Επικοινωνία | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
	},
	{
		path: '/profile-detail/:id',
		name: 'profile-detail',
		meta: {
			auth: true,
			title: 'Προφίλ | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/ProfileDetail.vue')
	},
	{
		path: '/flipbook-view/:id/:pageNumber?',
		name: 'flipbook-view',
		meta: {
			auth: true,
			title: 'Προφίλ | Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/FlipbookView.vue')
	},
	{
		path: '*', redirect: '/'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	},
	routes
})

export default router
