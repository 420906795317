<template>
	<div>
			<b-modal
				@hide="onHide()"
				class="text-modal"
				centered scrollable
				size="lg"
				v-model="modalShow"
				:header-border-variant="headerBorderVariant"
			>
			<div
				v-if="modalContent"
				class="img-cont"
			>
			<p
				v-html="modalContent"
			></p>
			</div>
			<template class="tex-modal" v-slot:modal-header>
				<div class="centering">
					<button type="button" @click="closeModal()" aria-label="Close" class="close" style="text-transform: lowercase"> x </button>
					<h6 style="border: none">{{ modalTitle }}</h6>
					<div class="seperator"></div>
				</div>
			</template>
			<template class="tex-modal" v-slot:modal-footer>
				<!-- <button class="btn btn-primary" @click="closeModal()"></button> -->
				<span> </span>
			</template>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: "TextModal",
	props: {
		modalTitle: {
			type: String,
			required: true,
			default: ''
		},
		modalContent: {
			type: String,
			required: true,
			default: ''
		}
	},
	data: () => {
		return {
			modalShow: false,
			headerBorderVariant: 'none'
		}
	},
	methods: {
		closeModal() {
			this.modalShow = false
		},
		onHide() {
			return true
		}
	}
}
</script>

<style lang="scss" scoped>

	.controls-container {
		width: 80%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.float-left {
			margin: 0 5px;
		}
	}

	.centering {
		width: 100%;
	}

	h6 {
		text-align: center;
		font-weight: 100;
		width: 100%;
		font-size: calc(14px + (36 - 14) * ((100vw - 300px) / (1600 - 300)));
		margin-bottom: 4%;
	}

	.seperator {
		margin: auto;
		position: relative;
		height: 1px;
		border-bottom: 1px solid #000;
		width: calc(40px + (80 - 40) * ((100vw - 300px) / (1600 - 300)));
	}

	p {
		text-align: justify;
		padding: 0 3%;
	}

.transcript {
	text-align: justify;
	padding: 3% 6%;
}

</style>
