import axios from 'axios'
import store from '../index'

const audioLink = "media?item_id=29399"

const state = {
	interviewPlaylist: [],
	artist: "Συνέντευξη Ελένης Αντωνιάδη Μπιμπίκου",
	isCompleted: false
}

const actions = {
	loadInterview({ commit }) {
		axios
			.get(`${store.getters.getUrl}${audioLink}`)
			.then((response) => {
				commit('updateAudiosList', response.data)
			})
	}
}

const mutations = {
	updateAudiosList(state, audioData) {
		for (let i = 0; i < audioData.length; i++) {
			let single = {}
			single.title = audioData[i][`dcterms:title`][0][`@value`]
			single.url = audioData[i][`o:original_url`]
			single.artist = state.artist
			state.interviewPlaylist.push(single)
		}
		state.isCompleted = true
	}
}

const getters = {
	getAudios: state => {
		return state.interviewPlaylist
	},
	audiosComplete: state => {
		return state.isCompleted
	}
}

export default {
	state,
	actions,
	mutations,
	getters
}
