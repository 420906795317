import store from '../index'
import axios from 'axios'

const singlePageLink = "site_pages?per_page=50"

const state = {
	allPagesData: [],
	titlesLoading: false
}

const actions = {
	loadPages({ commit }) {
		let singlePageUrl = `${store.getters.getUrl}${singlePageLink}`
		state.titlesLoading = true
		axios
			.get(singlePageUrl)
			.then((response) => {
				commit('changeTitlesStatus')
				commit('extractTitles', response.data)
			})
	},
	cleanUp({ commit }) {
		commit('clear')
	}
}

const mutations = {
	extractTitles(state, newData) {
		for (let i = 0; i < newData.length; i++) {
			let pageTitle = { }
			if (newData[i][`o:slug`].includes('archives-list-')) {
				pageTitle.title = newData[i][`o:title`]
				pageTitle.title_id = newData[i][`o:id`]
				state.allPagesData.push(pageTitle)
			}
		}
	},
	changeTitlesStatus(state) {
		state.titlesLoading = false
	},

	clear(state) {
		state.allPagesData = []
	}
}

const getters = {
	// return this.books.map(x => x.author);
	getTitles: state => {
		return state.allPagesData
	},

	getTitlesStatus: state => {
		return state.titlesLoading
	}

}

export default {
	state,
	actions,
	mutations,
	getters
}
