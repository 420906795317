<template>
	<div id="app">
		<div id="nav">
		<AppHeader
			@sidenavToggle="displaySidenav = !displaySidenav"
		/>
		<AppSidenav
			:show="displaySidenav"
			@close="displaySidenav = false"
		/>
		</div>
		<router-view />
		<AppFooter />
	</div>
</template>

<script>
import AppHeader from "@/components/Navigation/AppHeader"
import AppSidenav from "@/components/Navigation/AppSidenav"
import AppFooter from "@/components/AppFooter"

export default {
	components: {
		AppHeader,
		AppSidenav,
		AppFooter
	},
	data() {
		return {
			displaySidenav: false
		}
	},
	created() {
		document.title = `Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη`
		this.$store.dispatch('loadSubjectSuggestion')
		this.$store.dispatch('loadTagSuggestions')
	},
	watch: {
		'$route' (to, from) {
			document.title = to.meta.title || 'Αρχείο Ιδρύματος Αικατερίνης Λασκαρίδη'
		}
	}
}
</script>

<style lang="scss">
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#nav {
	padding: 0px;

	a {
		color: #2c3e50;
		white-space: nowrap;
	}
}

@media(max-width: 767px) {

#nav {
	padding: 0px;

	a {
		color: #2c3e50;
		white-space: pre-wrap;
	}
}
}
</style>
