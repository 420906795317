<template>
	<div class="archives centering">
		<!-- FRENCH BUTTON -->
		<div v-if="frTitle && frContent" class="fr-container">
			<button class="trigger-modal" style="background-color: #95b136" @click="triggerModal">FR</button>
		</div>
		<!-- FRENCH BUTTON -->
		<div class="description-container"
			v-html="descriptionContent"
		>
		</div>
		<div class="section-title centering">
			<h3 class="archives-title">Τα Αρχεία μας</h3>
			<div class="dash"></div>
		</div>
		<div class="card">
			<div class="section-title centering">
				<h3 class="archives-title-inner">Μη ψηφιοποιημένα αρχεία</h3>
				<div class="dash"></div>
			</div>
			<arch-titles></arch-titles>
			<div class="section-title centering">
				<h3 class="archives-title-inner">Ψηφιοποιημένα αρχεία</h3>
				<div class="dash"></div>
				<archive-card-title :items="archives" />
			</div>
			<!-- <arch-titles></arch-titles> -->
		</div>
		<p class="link-container">
			<text-modal ref="textModal"
				:modal-title="frTitle"
				:modal-content="frContent"
			></text-modal>
		</p>
		<!-- <div class="archives-options">
			<ArchiveCard
				v-for="item in archives"
				:key="item.id"
				:card-title="item.title"
				:bg-img="item.img"
				:arch-link="item.link"
				@click.native="goToArchive(item.id, item.content)"
				:class="{'available': isAvailable(item.id)}"
			/>

		</div> -->
		<div class="descriptions centering">
			<!-- <h2 class="archives-title">Sources</h2>
			<div class="dash"></div> -->
		</div>
	</div>
</template>

<script>
// import ArchiveCard from '@/components/ArchiveCard.vue'
import ArchTitles from '@/components/ArchTitles.vue'
import TextModal from "@/components/ui/TextModal.vue"
import ArchiveCardTitle from "@/components/ArchiveCardTitle.vue"

export default {
	name: "ArchivesMenu",
	components: {
		// ArchiveCard,
		TextModal,
		ArchTitles,
		ArchiveCardTitle
	},
	data() {
		return {
			descriptionContent: "",
			frTitle: "",
			frContent: ""
		}
	},
	beforeCreate() {
		this.$store.dispatch('loadArchivesList')
	},
	created() {
		this.getDescription()
		this.getFrench(37)
	},
	computed: {
		archives() {
			return this.$store.state.archives_menu
		}
	},
	methods: {
		getImgPath(i) {
			return `${i}`
		},
		goToArchive(id, page) {
			// console.log(id)
			this.$store.dispatch('setCurrenCollection', id)
			this.$router.push({ path: `/archives/${id}/${page}` })
		},
		isAvailable(id) {
			return (id === 1)
		},
		getDescription() {
			this.$http
				.get(`${this.$store.state.rootUrl}site_pages/37`)
				.then((response) => {
					this.descriptionContent = response.data[`o:block`][1][`o:data`].html
				})
		},
		getFrench(slug) {
			if (slug) {
				let contentUrl = `${this.$store.state.rootUrl2}pages/${slug}`
				this.$http.get(contentUrl)
					.then((res) => {
						this.frTitle = res.data.page.fr_title
						this.frContent = res.data.page.fr_content
					})
			}
		},
		triggerModal() {
			this.$refs.textModal.modalShow = true
		}
	},
	mounted() {
		// console.log(this.$store.state.archives_menu)
	}
}
</script>

<style scoped lang="scss">
	.archives {
		background-color: $light-grey;
		padding: 5%;

		.dash {
			margin: 1.5% 2% 1.5% 2%;
		}

		.fr-container {
			padding: 0 5%;
		}
	}

	.descriptions {
		margin: 4% 0;
	}
	.description-container {
		width: 75%;
	}

	h2 {
		margin: 0;
		font-size: 55px;
		font-weight: 100;
	}

	.available {
		cursor: pointer
	}

	.section-title {
		margin-top: 3%;
		width: 100%;
	}
	.archives-title-inner{
		font-size: 1.25rem;
	}

	.archives-options {
		display: flex;
		flex-wrap: wrap;
		width: 95%;
	}

	.card {
		margin: 1rem 3rem;
		margin-bottom: 3rem;
		padding: 4% 1% 2% 1%;
		background: white;
		box-shadow: 2px 4px 25px rgba(0, 0, 0, .1);
		border-radius: 12px;
		overflow: hidden;
		transition: all .2s linear;
	}

	.fr-container {
		width: 100%;
    text-align: right;

		.trigger-modal {
			background-color: #95b136;
			border: none;
			color: #fff;
			border-radius: 5px;
			-webkit-appearance: none;
			-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
		}
	}

	@media(max-width: 960px) {

		.archives-options {
			flex-direction: column;
			align-items: center;
			width: 60%;
		}

		.description-container {
			width: 80%;
		}

	}

@media (max-width: 775px) {
	h2 {
		font-size: 44px;
	}

	.archives-options {
		width: 85%;
	}

}

@media (max-width: 550px) {

	.archives {
		.card {
			margin: 1rem 1.5rem;
		}
	}

	h2 {
		font-size: 34px;
	}

	.description-container {
		width: 100%;
		padding-top: 10px;
	}

}

@media (max-width: 450px) {

	.archives {
		.card {
			margin: 1rem .5rem;
		}
	}

	.archives-options {
    width: 100%;
	}
}
</style>
