import Vue from 'vue'
import App from './App.vue'
import GenericPage from './components/GenericPage.vue'
import Loader from "@/components/Navigation/Loader.vue"
import BootstrapVue from 'bootstrap-vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@babel/polyfill'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBookOpen, faTimesCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'leaflet/dist/leaflet.css'

import { Icon } from 'leaflet'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

library.add(faBookOpen)
library.add(faTimesCircle)
library.add(faPlayCircle)

Vue.use(BootstrapVue)

Vue.prototype.$http = axios

Vue.config.productionTip = false

Vue.component('generic-page', GenericPage)
Vue.component('Loader', Loader)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
