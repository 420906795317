import axios from 'axios'

const Modifiers = {

	getItems(data) {
		const itemsArray = []
		for (let i = 0; i < data.length; i++) {
			itemsArray[i] = this.modifyArchiveItem(data[i])
		}
		return itemsArray
	},

	modifyArchiveItem(singleItem) {
		// console.log(" singleItem  ", singleItem)
		let textData = {}
		textData.id = singleItem[`o:id`]
		textData.itemSetId = singleItem['o:item_set'][0][`o:id`]
		textData.title = singleItem[`dcterms:title`][0][`@value`]
		textData.generalFolderNum = singleItem[`dcterms:General Folder Number`] ? singleItem[`dcterms:General Folder Number`][0][`@value`] : ' '
		textData.generalFolderName = singleItem[`dcterms:General Folder Name`] ? singleItem[`dcterms:General Folder Name`][0][`@value`] : ''
		textData.folderNumber = singleItem[`dcterms:Folder Number`] ? singleItem[`dcterms:Folder Number`][0][`@value`] : ''
		textData.fileNumber = singleItem[`dcterms:File Number`] ? singleItem[`dcterms:File Number`][0][`@value`] : ''
		textData.subject = singleItem[`dcterms:subject`] ? singleItem[`dcterms:subject`][0][`@value`] : ''
		textData.tags = singleItem[`o-module-folksonomy:tag`] ? this.getTags(singleItem[`o-module-folksonomy:tag`]) : ''

		textData.mainImg = singleItem[`foaf:thumbnail`] ? singleItem[`foaf:thumbnail`][1][`@id`] : ' '
		textData.description = singleItem[`dcterms:description`] ? singleItem[`dcterms:description`][0][`@value`] : ''
		textData.source = singleItem[`dcterms:source`] ? singleItem[`dcterms:source`][0][`@id`] : ''
		textData.sourceTitle = singleItem[`dcterms:source`] ? singleItem[`dcterms:source`][0][`o:label`] : ''
		textData.pages = singleItem[`dcterms:Pages`] ? singleItem[`dcterms:Pages`][0][`@value`] : ''
		textData.place = singleItem[`dcterms:Place`] ? singleItem[`dcterms:Place`][0][`@value`] : ''
		textData.sender = singleItem[`dcterms:Sender`] ? singleItem[`dcterms:Sender`][0][`@value`] : ''
		textData.receiver = singleItem[`dcterms:Receiver`] ? singleItem[`dcterms:Receiver`][0][`@value`] : ''
		textData.type = singleItem[`dcterms:type`] ? singleItem[`dcterms:type`][0][`@value`] : ''
		textData.source = singleItem[`dcterms:source`] ? singleItem[`dcterms:source`][0][`@value`] : ''
		textData.pdfImages = []
		if (singleItem[`dcterms:source`]) {
			for (let i = 0; i < singleItem[`dcterms:source`].length; i++) {
				if (singleItem[`dcterms:source`][i]['type'] === 'resource' && singleItem[`dcterms:source`][i]['value_resource_name'] === 'media') {
					let thumbnailCorrected = singleItem[`dcterms:source`][i]['thumbnail_url']
					thumbnailCorrected = thumbnailCorrected.replace("square", "original")
					textData.pdfImages.push(thumbnailCorrected)
				}
			}
		}
		// textData.pdfImages = singleItem[`dcterms:source`] ? singleItem[`dcterms:source`] : ''
		textData.identifier = singleItem[`dcterms:identifier`] ? singleItem[`dcterms:identifier`][0][`@value`] : ''
		textData.language = singleItem[`dcterms:language`] ? singleItem[`dcterms:language`][0][`@value`] : ''
		textData.date = singleItem[`dcterms:date`] ? singleItem[`dcterms:date`][0][`@value`] : ''
		textData.location = singleItem['dcterms:Location'] ? singleItem['dcterms:Location'][0]['@value'] : ""
		textData.geographicalarea = singleItem['dcterms:Geographical Area'] ? singleItem['dcterms:Geographical Area'][0]['@value'] : ""
		textData.provenance = singleItem['dcterms:provenance'] ? singleItem['dcterms:provenance'][0]['@value'] : ""
		textData.latitude = singleItem['dcterms:Latitude'] ? singleItem['dcterms:Latitude'][0]['@value'] : ""
		textData.longitude = singleItem['dcterms:Longitude'] ? singleItem['dcterms:Longitude'][0]['@value'] : ""
		textData.collection = singleItem['dcterms:Collection'] ? singleItem['dcterms:Collection'][0]['@value'] : ""
		textData.wiki = singleItem['dcterms:Wiki'] ? singleItem['dcterms:Wiki'][0]['@value'] : ""
		textData.dimensions = singleItem['dcterms:Dimensions'] ? singleItem['dcterms:Dimensions'][0]['@value'] : ""

		return textData
	},

	getParentItems(listMenu) {
		let menuList = []
		let menu = listMenu.filter(item => item['dcterms:type'])
			.filter(parentItem => parentItem['dcterms:type'][0]['@value'] === 'parent' || parentItem['dcterms:type'][0]['@value'] === 'parent, laskari')
		for (let i = 0; i < menu.length; i++) {
			menuList[i] = this.reducer(menu[i])
		}
		return menuList
	},

	reducer(obj) {
		// if (obj['o:is_public']) {
		return {
			id: obj['o:id'],
			title: obj['dcterms:title'][0]['@value'],
			img: obj['o:thumbnail'] ? obj['o:thumbnail']['o:id'] : 0,
			content: obj['foaf:page'] ? obj['foaf:page'][0]['@value'] : 0
		}
		// }
	},

	getTags(tags) {
		const tagNames = []
		for (let i = 0; i < tags.length; i++) {
			let tag = {
				id: i + tags[i][`o:id`],
				gr: tags[i][`o:id`],
				fr: ''
			}
			axios.get(`https://laskaridisfoundationarchives.org/tags_admin/api/tags?tag=${tag.gr}`)
				.then(res => {
					tag.fr = res.data.tag
				})
			tagNames[i] = tag
		}
		return tagNames
	},

	getAllSets(dataList) {
		const itemSetsArray = []
		for (let i = 0; i < dataList.length; i++) {
			// let itemSlider = dataList[i]['foaf:img'] && dataList[i]['foaf:img'][1] ? dataList[i]['foaf:img'][1]['@id'] : false
			itemSetsArray.push({
				id: +dataList[i]['o:id'],
				title: dataList[i]['o:title'],
				shortTitle: dataList[i]['bibo:shortTitle'] ? dataList[i]['dcterms:title'][0]['@value'] : dataList[i]['o:title'],
				isParent: dataList[i]['dcterms:type'] ? dataList[i]['dcterms:type'][0]['@value'] : false,
				thumbnail: dataList[i]['o:thumbnail'] ? dataList[i]['o:thumbnail']['o:id'] : null,
				pageID: dataList[i]['foaf:page'] ? dataList[i]['foaf:page'][0]['@value'] : 0,
				textImg: dataList[i]['foaf:img'] ? dataList[i]['foaf:img'][0]['@id'] : false,
				slider: dataList[i]['foaf:img'] ? this.itemSlider(dataList[i]['foaf:img']) : false,
				hasFr: !!dataList[i]['foaf:status'],
				subs: dataList[i]['dcterms:references'] ? this.getChildren(dataList[i]['dcterms:references'], dataList) : [],
				format: dataList[i]['dcterms:format'] ? dataList[i]['dcterms:format'][0]['@value'] : "table",
				location: dataList[i]['dcterms:Location'] ? dataList[i]['dcterms:Location'][0]['@value'] : "",
				geographicalarea: dataList[i]['dcterms:geographicalarea'] ? dataList[i]['dcterms:geographicalarea'][0]['@value'] : ""

			})
		}
		return itemSetsArray
	},

	itemSlider(item) {
		let slider = ''
		for (let i = 0; i < item.length; i++) {
			let x = item[i]['@id'].includes('slider')
			if (x) {
				slider = item[i]['@id']
			}
		}
		return slider
	},

	getChildren(refArray, dataList, itemSlider) {
		const children = []
		for (let i = 0; i < refArray.length; i++) {
			children.push({
				id: +refArray[i].value_resource_id,
				title: refArray[i].display_title,
				thumbnail: this.getThumbnail(refArray[i].value_resource_id, dataList),
				textID: this.getCollectionText(refArray[i].value_resource_id, dataList),
				shortTitle: this.getShortTitle(refArray[i].value_resource_id, dataList),
				slider: itemSlider
			})
		}
		// console.log(children)
		return children
	},

	getThumbnail(id, dataList) {
		const selection = dataList.filter(x => x['o:id'] === id)
		return selection[0]['o:thumbnail']
	},

	getCollectionText(id, dataList) {
		const selection = dataList.filter(x => x['o:id'] === id)
		return selection[0]['foaf:page'] ? selection[0]['foaf:page'][0]['@value'] : 0
	},

	getShortTitle(id, dataList) {
		const selection = dataList.filter(x => x['o:id'] === id)
		return selection[0]['bibo:shortTitle'] ? selection[0]['bibo:shortTitle'][0]['@value'] : selection[0]['o:title']
	},
	modifyArchiveItemSingle(singleItem) {
		let textData = {}
		textData.id = singleItem[`o:id`]
		textData.title = singleItem[`dcterms:title`][0][`@value`]
		textData.mainImg = singleItem[`foaf:thumbnail`] ? singleItem[`foaf:thumbnail`][1][`@id`] : ' '
		textData.description = singleItem[`dcterms:description`]
		textData.source = singleItem[`dcterms:source`]
		textData.tags = singleItem[`o-module-folksonomy:tag`] ? this.getTags(singleItem[`o-module-folksonomy:tag`]) : ''
		textData.profileImages = []
		return textData
	}

}

export default Modifiers
