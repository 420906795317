]<template>
	<div
		:style="{ 'background-image': 'url(' + createPath(backgroundImg) + ')' }"
		class="header-content">
		<div class="page-header"
		>
			<h1 class="header-title">
				{{ title }}
			</h1>
			<p class="lead">
				{{ description }}
			</p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: "PageBanner",
	props: {
		title: {
			type: String,
			required: false,
			default() {
				return ''
			}
		},
		description: {
			type: String,
			required: false,
			default() {
				return ''
			}
		},
		backgroundImg: {
			type: String,
			required: false,
			default() {
				return ""
			}
		},
		itemSetId: {
			type: Number,
			required: false,
			default() {
				return 0
			}
		}
	},
	data() {
		return {
			hasSearch: true
		}
	},
	computed: {
		...mapGetters({
			currentCollection: 'getCurrentCollection'
		})
	},
	methods: {
		createPath(i) {
			if (this.currentCollection.slider) {
				return this.currentCollection.slider
			}
			// return require(`../assets/images/slider_archive.jpg`)
			const itemSetIds = [42360]
			if (itemSetIds.includes(this.itemSetId)) {
				return require(`../assets/images/slider_archive-2.png`)
			} else {
				return require(`../assets/images/slider_archive.jpg`)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	.header-content {
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		min-height: 34vh;
	}

	.page-header {
		margin: 5% 0 7% 0;
	}

	h1 {
		margin-top: 14%;
		font-size: 30px;
		font-weight: 700;
	}

	.lead {
		color: #fff;
		font-size:22px;
		font-weight: 400;
	}

	.search-container {
		width: 70%;
		max-width: 800px;
		margin-bottom: 5%;
	}

	// .filters {
	// 	background-color: #ffffff7a;
	// 	width: 100%;
	// 	display: flex;
	// }

	// .filters span {
	// 	font-weight: 700;
	// }

	// .filters > div {
	// 	width: 100%;
	// 	justify-content: space-around
	// }

	// .filters .v-input {
	// 	margin: auto 1%;
	// }

	// .theme--light.v-text-field--solo-inverted.v-text-field--solo>.v-input__control {
	// 	background: #fff!important;
	// }

	// .filters .v-input__control {
	// 	background: #fff!important;
	// }

	// .primary {
	// 	background-color: $action-blue!important;
	// }

@media(max-width: 960px) {

	.page-header {
		width: 85%;
	}

	.lead {
		font-size: 18px;
	}
}

@media(max-width: 670px) {
	.header-content {
		min-height: 15vh;
	}
}
</style>
