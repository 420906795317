<template>
  <div class="archives-info">
    <b-spinner class="title-spinner" v-if="isLoading" type="border" large></b-spinner>
    <div v-if="!isLoading" class="lists">
      <ul class="left-list">
        <li
          class="single-title card"
          v-for="item in items"
          :key="item.id"
          @click="goToArchive(item.id,item.content)"
        ><font-awesome-icon class="book-icon" icon="book-open" />
				<p>{{ item.title }}</p>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
	name: "ArchiveCardTitle",
	props: {
		items: {
			type: Array,
			required: true,
			default() {
				return []
			}
		}
	},
	data() {
		return {
			isLoading: true
		}
	},
	mounted() {
		this.loadItems()
	},
	methods: {
		loadItems() {
			setTimeout(() => {
				this.isLoading = false
			}, 500)
		},
		getItemImage(imgId) {
			if (imgId) {
				return `${this.$store.state.rootUrl}assets/${imgId}`
			}
			return `https://picsum.photos/id/1073/600/800`
		},
		goToArchive(id, page) {
			this.$store.dispatch('setCurrenCollection', id)
			this.$router.push({ path: `/archives/${id}/${page}` })
		}
	}
}
</script>

<style scoped lang="scss">

.title-spinner {
	height: 4rem;
	width: 4rem;
	border: 0.25rem solid $tur-blue;
	border-right-color: transparent;
}

.titles-container {
	display: flex;
	flex-wrap: wrap;
}

.card {
	// margin: 1rem;
	padding: 1% 3% 0% 4%;;
	display: inline-flex;
	justify-content: space-between;
	// background: white;
	// box-shadow: 2px 4px 25px rgba(0, 0, 0, .1);
	border-radius: 12px;
	border: none;
	overflow: hidden;
	// transition: all .2s linear;
	// font-weight: 700;
	cursor: pointer;
}

.rotate {
	transform: rotate(270deg);
}
.btn-wrapper {
	cursor: pointer;
}
.more-titles {
	width: 11px;
	cursor: pointer;
	transform: rotate(90deg);
}

#nav-icon3 {
  width: 30px;
  height: 17px;
  position: relative;
  margin: 20px auto 10px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  cursor: pointer;
  position: absolute;
  height: 4px;
  width: 100%;
	background-color: $tur-blue;
  border-radius: 9px;
  opacity: 1;
	left: 0;
	margin: 3px 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 9px;
}

#nav-icon3 span:nth-child(4) {
  top: 18px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

// .card:hover {
//   box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
//   transform: translate3D(0, -2px, 0);
// }

@media screen and (min-width: 600px) {
	.card {
		flex: 1 1 calc(50% - 2rem);
	}
}

@media screen and (min-width: 900px) {
	.card {
		flex: 1 1 calc(25% - 2rem);
	}
}

// .card:nth-child(2n) {
//   background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
// }

// .card:nth-child(4n) {
//   background-image: linear-gradient(120deg, #ff9a9e 0%, #fecfef 100%);
// }

// .card:nth-child(5n) {
//   background-image: linear-gradient(120deg, #ffc3a0 0%, #ffafbd 100%);
// }

ul {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;

		.single-title {
			cursor: pointer;
		}
}

	.archives-info {
		width: 100%;
		padding: 0 0% 2% 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		// grid-column-gap: 20px;
	}

	.lists {
		display: flex;
		width: 100%;
		min-width: 90px;
		text-align: left;
		margin: 0 0% 0 0%;
		height: 100%;
		justify-content: center;

		ul {
			text-align: left;
			list-style-type: none;
			padding: 0 3.5%;
		}

		.left-list {
			margin-left: 1%;
		}

		.center-list {
			padding: 0;
		}

		li {
			line-height: 1.4;
			font-size: 17px;
			margin: 2% 0%;
			display: inline-flex;
			justify-content: space-between;
			// display: flex;
			min-width: 51%;

			p {
				margin-left: 25px;
				margin-top: -20px;
			}
		}

		li:hover {
			p {
				color: $tur-blue;
			}

			.book-icon {
				color: $tur-blue;
			}
		}
	}

	@media(max-width: 1100px) {

				ul {
			-webkit-column-count: 2;
			-moz-column-count: 2;
			column-count: 2;
		}

		.single-title p {
			font-size: 1rem;
		}

	}

	@media(max-width: 960px) {

		.archives-info {
			padding-right: 0%;
		}

		.lists{
			width: 100%;

				.left-list {
					margin-left: 0%;
				}

				.center-list {
					padding: 0 5%;
				}

				li {
					font-size: 20px;
				}
		}
	}

@media (max-width: 775px) {

	.lists {
		flex-direction: column;
		width: 100%;
		align-items: center;

		li {
			font-size: 18px;
		}
	}

		ul {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1;
	}

}

@media (max-width: 550px) {
	h2 {
		font-size: 34px;
	}

	.lists {
		li {
			font-size: 16px;
		}
	}

}
/*
// Set the ul to use CSS grid for consistent column widths
.lists {
	display: flex;
	width: 100%;
	min-width: 90px;
	text-align: left;
	margin: 0 0% 0 0%;
	height: 100%;
	justify-content: center;

	ul {
		display: grid;
		grid-template-columns: repeat(3, 1fr); // Three columns
		gap: 1rem; // Space between items
		width: 100%;
		list-style-type: none;
		padding: 0 3.5%;
	}

	.left-list {
		margin-left: 1%;
	}

	li {
		line-height: 1.4;
		font-size: 17px;
		margin: 2% 0%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

		p {
			margin-left: 1rem;
		}
	}

	li:hover {
		background-color: #f0f8ff; // Light background on hover
		p {
			color: $tur-blue;
		}

		.book-icon {
			color: $tur-blue;
		}
	}
}

// Responsive Adjustments
@media(max-width: 1100px) {
	.lists ul {
		grid-template-columns: repeat(2, 1fr); // Two columns
	}

	.single-title p {
		font-size: 1rem;
	}
}

@media(max-width: 775px) {
	.lists ul {
		grid-template-columns: repeat(1, 1fr); // One column for smaller screens
	}
}

@media(max-width: 550px) {
	.lists li {
		font-size: 16px;
	}
}
 */
</style>
