<template>
	<div class="sidenav-container">
		<div v-if="show" class="sidenav-backdrop" @click="$emit('close')" />
		<transition name="slide-side">
			<div v-if="show" class="sidenav">
				<ul class="nav-list" @click="$emit('close')">
					<li class="nav-item">
						<router-link to="/"><img width="150" src="../../assets/images/logo.png" alt=""></router-link>
					</li>
					<li class="nav-item">
						<router-link to="/" exact>Αρχική</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/about">Ποιοι Είμαστε</router-link>
					</li>
					<!-- <li class="nav-item">
						<b-nav-item-dropdown right>
							<template v-slot:button-content>
								<a href=""
									:class="{ 'router-link-active': subIsActive('/archives')}"
								>Archives
								</a>
							</template>
							<b-dropdown-item>
								<router-link to="/archives" class="submenu-opt">
									Eleni Antoniadis Bibikou Archive
								</router-link>
								</b-dropdown-item>
						</b-nav-item-dropdown>
					</li> -->
					<li class="nav-item">
						<b-nav-item-dropdown right>
							<!-- Using 'button-content' slot -->
							<template v-slot:button-content>
								<a href=""
									:class="{ 'router-link-active': subIsActive('/archives')}"
								>Αρχεία
								</a>
							</template>
						<b-dropdown-item
							v-for="link in archives"
							:key="link.id"
							:to="`/archives/${link.id}/${link.content}`"
						>
						<span class="menu-link">{{ link.title }}</span>
						</b-dropdown-item>
						</b-nav-item-dropdown>
					</li>
					<li class="nav-item">
						<router-link to="/contact">Επικοινωνία</router-link>
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: "AppSidenav",
	props: {
		show: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			activeClass: 'router-link-exact-active router-link-active',
			showSub: false
		}
	},
	computed: {
		currentPage() {
			return this.$route.path
		},
		submenu() {
			return `Eleni Antoniadis Bibikou Archive`
		},
		archives() {
			return this.$store.state.archives_menu
		}
	},
	methods: {
		archiveSelected() {
			// console.log("go")
			this.$router.push({ path: "/archives" })
		},
		subIsActive(input) {
			const paths = Array.isArray(input) ? input : [input]
			return paths.some(path => {
				return this.$route.path.indexOf(path) === 0 // current path starts with this path string
			})
		}
	}
}
</script>

<style scoped lang="scss">
.sidenav-container {
	height: 100%;
	width: 100%;
}

.sidenav-backdrop {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
}

.sidenav {
	height: 100%;
	width: 300px;
	background-color: $light-grey;
	z-index: 10000;
	position: fixed;
	top: 0;
	left: 0;
	box-sizing: border-box;
	padding: 30px;
}

.slide-side-enter-active,
.slide-side-leave-active {
	transition: all 0.3s ease-out;
}
.slide-side-enter,
.slide-side-leave-to {
	transform: translateX(-100%);
}

.nav-list {
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: center;
}

.nav-list .nav-item:first-child {
	margin-bottom: 20%;
}

.nav-item {
	margin: 20px 0;
}

.nav-item a {
	text-decoration: none;
	color: #fff;
	font-size: 1.5rem;
}

.nav-item a:hover,
.nav-item a:active {
	// text-shadow: 2.5px 1.5px 5px rgba(0,0,0,0.75)!important;
}

.nav-item:not(:first) a:hover,
.nav-item:not(:first) a:active,
.nav-item:not(:first) a.router-link-active {
  color: #365e65;
  font-weight: 700;
	border-bottom: 2px solid $tur-blue;
}

.nav-item a:active,
.nav-item a.router-link-active {
	font-weight: bold;
}

.dropdown-item .submenu-opt {
	color: $dark;
	font-size: 15px;
}

.submenu-opt:focus,
.submenu-opt:hover {
	color: $dark
}

.nav-item:last-child {
	margin-top: 0;
}

.menu-link {
	padding: 2% 0;
}

li > li.dropdown {
	margin-bottom: 0!important;
}
</style>
