<template>
	<div
		:class="{isHome: isHomepage, 'fixed-padding': !isHomepage, 'min_height': !isBibikou}"
		class="filters centering"
	>
	<label class="section-title">
		ΑΝΑΖΗΤΗΣΗ ΑΡΧΕΙΟΥ
	</label>
		<b-form @submit.prevent="" class="search_form" :class="{'has-transcribus': !isBibikou}">
			<div class="input-container src_title">
				<b-form-input
					v-model="queryTitle"
					id="inline-form-input-title"
					class="search-input mr-sm-2 mb-0"
					placeholder="Τίτλος"
				></b-form-input>
			</div>
			<div class="input-container src_subject">
				<autocomplete
					ref="autoComp1"
					:choose-lang="false"
					class="autotest"
					@input="setSubject"
					:pl-holder="'Θέμα'"
					:items="subjectItems"
				/>
			</div>
			<div class="input-container src_tag">
				<autocomplete
					ref="autoComp2"
					:choose-lang="hasLangOption"
					@lang-changed="setTagLang"
					@input="setTag"
					class="autotest"
					:pl-holder="'Ετικέτες'"
					:items="tagsArray"
				/>
			</div>
			<div v-if="isBibikou && !isHomepage" class="cbx-container input-container">
			<input
				id="cbx"
				v-model="isTranscript"
				type="checkbox"/>
			<label class="cbx" for="cbx">
				<div class="flip">
					<div class="front"></div>
					<div class="back">
						<svg width="16" height="14" viewBox="0 0 16 14">
							<path d="M2 8.5L6 12.5L14 1.5"></path>
						</svg>
					</div>
				</div>
			</label>
			<span>Μεταγραφές</span>
			</div>

			<b-button class="src-btn mb-lg-0" :class="{ 'btn-secondary': isSecondary }" type="button" @click="triggerSearch" variant="primary">ΑΝΑΖΗΤΗΣΗ</b-button>
			<div
				class="clear-container"
				:class="{hideMe: !isWritingTitle && !isWritingSubject && !isWritingTag}">
			<font-awesome-icon
				@click="clearQuery"
				class="clear-btn" icon="times-circle"
			/>
		</div>
		</b-form>
	</div>
</template>

<script>
import AutoComplete from '@/components/ui/AutoComplete.vue'
import { mapGetters } from 'vuex'

export default {
	name: "ArchFilters",
	props: {
		hasTrans: Boolean
	},
	components: {
		'autocomplete': AutoComplete
	},
	data() {
		return {
			isSecondary: false
		}
	},
	computed: {
		...mapGetters({
			subjectItems: 'getAllSubjects',
			grTagItems: 'getGrTags',
			frTagItems: 'getFrTags',
			currentCollection: 'getStoredCollection'
		}),
		queryTitle: {
			get() {
				return this.$store.getters['getQueryTitle']
			},
			set(value) {
				this.$store.commit('updateQueryTitle', value)
			}
		},
		querySubject: {
			get() {
				return this.$store.getters['getQuerySubject']
			},
			set(value) {
				this.$store.commit('updateQuerySubject', value)
			}
		},
		queryTag: {
			get() {
				return this.$store.getters['getQueryTag']
			},
			set(value) {
				this.$store.commit('updateQueryTag', value)
			}
		},
		isFrench: {
			get() {
				return this.$store.getters['searchFr']
			},
			set(value) {
				this.$store.commit('setFRsearch', value)
			}
		},
		isTranscript: {
			get() {
				return this.$store.getters['searchTrans']
			},
			set(value) {
				this.$store.commit('setTransMode', value)
			}
		},
		tagsArray() {
			if (this.isFrench) {
				return this.frTagItems
			}
			return this.grTagItems
		},
		isWritingSubject() {
			return this.querySubject.length > 0
		},
		isWritingTitle() {
			return this.queryTitle.length > 0
		},
		isWritingTag() {
			return this.queryTag.length > 0
		},
		isHomepage() {
			return this.$router.currentRoute.path === "/"
		},
		hasLangOption() {
			return !this.isHomepage && this.isBibikou
		},
		isBibikou() {
			if (this.currentCollection) {
				return this.currentCollection.hasFr
			}
			return false
		}
	},
	created() {
		// this.getSuggestions()
	},
	methods: {
		setSubject(e) {
			this.querySubject = e
		},
		setTag(e) {
			this.queryTag = e
		},
		setTagLang(lang) {
			this.isFrench = lang === 'FR'
		},
		triggerSearch() {
			this.$store.dispatch('clearSearchTag')
			this.$store.commit('gotoSearchStart')
			this.$emit('newSearch')
			if (this.isHomepage) {
				this.$router.push("search")
			}
		},
		resetTitle() {
			this.queryTitle = ''
			if (!this.isHomepage) {
				this.triggerSearch()
			}
		},
		resetSubject() {
			this.querySubject = ''
			if (!this.isHomepage) {
				this.triggerSearch()
			}
		},
		resetTags() {
			this.queryTag = ''
			if (!this.isHomepage) {
				this.triggerSearch()
			}
		},
		initializeSub() {
			let search = this.$store.getters['getQuerySubject']
			this.$refs.autoComp1.search = search
		},
		initializeTag() {
			let search = this.$store.getters['getQueryTag']
			this.$refs.autoComp2.search = search
		},
		clearQuery() {
			this.isFrench = false
			this.isTranscript = false
			this.$refs.autoComp1.search = ''
			this.$refs.autoComp2.selectedLang = 'ΕΛ'
			this.$refs.autoComp2.search = ''
			this.$store.commit('updateQueryTag', '')
			this.$store.commit('clearItemSet')
			this.resetTitle()
			this.resetSubject()
			this.resetTags()
			this.triggerSearch()
		}
	},
	mounted() {
		this.initializeSub()
		this.initializeTag()
		const validIdsSet = [42360]
		const idURL = parseInt(this.$route.params.id)
		if (validIdsSet.includes(idURL)) {
			this.isSecondary = true
		}
	}
}
</script>

<style lang="scss" scoped>
	input {
		box-shadow: inset 0.5px 0.5px 1.5px 0 rgba(0, 0, 0, 0.5);
	}

	.fixed-padding {
		padding: 3% 1%;
	}

	.section-title {
		font-weight: 700;
		font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
		margin: 0 0 1% 0;
	}

	.filters {
		background-color: $light-green;
		height: 100%;
		width: 100%;

		&not .isHome {
		}

		form {
			width: 90%;
			max-width: 1450px;
			height: 50px;
			padding: 0 10%;
			text-transform: capitalize;
			display: grid;
			grid-template-columns: 20% 20% 20% 18% 20% 2%;
			// grid-template-columns: repeat(5, 20% [col-start]);
			grid-template-rows: 50px;
			grid-column-gap: 10px;
			justify-items: center;
			align-items: center;

			label {
				margin-bottom: 0;
				margin-top: 3px;
			}

			.src_title {
				grid-column: 1;
			}

			.src_subject {
				grid-column: 2;
			}

			.src_tag {
				grid-column: 3;
			}

			.clear-container {
				height: 15px;
				width: 90%;
				padding: 0 10%;
				text-align: left;
				margin-top: -15px;
				grid-column: 6;
			}
		}

		.has-transcribus{
			grid-template-columns: 20% 20% 20% 0% 20% 2%;
			margin-left: 12%;

			.src-btn {
				margin-left: -7%;
			}
		}

		span {
			height: 100%;
			text-transform: capitalize;
			padding-left: 8px;
			padding-bottom: 2px;
			font-size: 18px;
			margin-right: 1%;
			font-weight: 400;
		}

		.input-container {
			display: block;
			max-width: 90%;

			> div {
				margin: auto 0;
			}
		}

		@-moz-document url-prefix() {
			.cbx-container {
				margin-left: 30px;
			}
		}

		.src-btn {
			background-color: $tur-blue;
			border: none;
			-webkit-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			-moz-box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			box-shadow: inset -6px -8px 60px -32px rgba(0,0,0,0.75);
			width: 90%;
			margin-left: 2%;
			align-self: middle;
			grid-column: 5;
		}

		.src-btn:active,
		.src-btn:focus {
			box-shadow: none!important;
			background-color: $tur-blue!important;
			border: $light-green;
		}

		.hideMe{
			visibility: hidden;
		}

	}

	.icon-container {
		margin: auto 6px;
		font-size: 17px;
		cursor: pointer;

		>* {
			color: $tur-blue!important;
			cursor: pointer;
		}
	}

	.clear-btn {
		color: gray;
		cursor: pointer;
	}

	.isHome {
		border-radius: 30px;
		// opacity: 0.7;
		width: 60vw;
		height: 110px;
		background-color: #f6f6eda1;

		.section-title {
			margin: 0;
			margin-top: -10px;
			margin-bottom: 20px;
		}

		span {

		}

		form {
			padding: 0 2% 4% 2%;
			width: 98%;
			height: 25px;
			margin-top: -15px;
			grid-column-gap: 6px;
			grid-template-columns: 20% 20% 20% 0% 20% 2%;
			margin-left: 12%;
		}

		.clear-container {
			padding: 0 1%;
			width: 100%;
		}

		.src-btn {
		}
		.clear-btn {
			transform: translateX(-10px);
		}

		#inline-form-input-title {
			margin-right: -0.5rem!important;
		}

	}

	form .cbx-container {
		// width: 30px;
		grid-column: 4;
		display: flex!important;
		justify-content: space-around;

		span {
			font-size: 18px;
		}
	}

	.suggestion {
		// position: absolute;
		// margin-left: -10%;
	}

	.cbx {
  -webkit-perspective: 20;
  perspective: 20;
  position: relative;
  border: 2px solid #e8e8eb;
  background: #e8e8eb;
  border-radius: 12px;
  transform: translate3d(0, 0, 0);
  cursor: pointer;
	transition: all 0.3s ease;
	width: 22px;
  height: 22px;
}
.cbx:hover {
  border-color:  $success;
}
.flip {
  display: block;
  transition: all 0.4s ease;
  transform-style: preserve-3d;
  position: relative;
  width: 20px;
  height: 20px;
}
#cbx {
  display: none;
}
#cbx:checked + .cbx {
  border-color: $success;
}
#cbx:checked + .cbx .flip {
  transform: rotateY(180deg);
}
.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 12px;
}
.front {
  background: #fff;
  z-index: 1;
}
.back {
  transform: rotateY(180deg);
  background:  $success;
  text-align: center;
  color: #fff;
  line-height: 20px;
  box-shadow: 0 0 0 1px $success;
}
.back svg {
  fill: none;
}
.back svg path {
  stroke: #fff;
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}

	.isHome:focus-within {
		// background-color: #f6f6ed;
	}

	@media(max-width: 1450px) {
		.filters {
			span {
				margin-right: 2%;
			}

			form {
				// width: auto;
			}
		}

		.isHome {
			padding-bottom: 0;
			width: 72vw;

			form {
				width: 100%;

				.cbx {
					margin-top: 7px;
				}
			}

			span {
				font-size: 16px;
				line-height: 2;
			}
		}
	}

	@media(max-width: 1200px) {

		.filters {

			form {
				padding: 0 4%;
			}

			span {
				font-size: 17px;
				margin-right: 2%;
			}
		}

		.isHome {
			width: 85%;
			padding-bottom: 0;

			span {
				font-size: 15px;
			}
		}

	}

	@media(max-width: 1100px) {

		.filters {

			.src-btn {
				// width: 18%;
			}

		}

		.isHome {
			width: 100%;
			padding: 0 1%;

			.src-btn {
				margin-top: 0;
			}
		}
	}

	@media(max-width: 1024px) {
		.filters {
			padding: 5% 1%;
			span {
				margin-right: 0;
				margin: 0  2% 2% 2%;
				height: 40%;
			}

			form {
				justify-content: center;
				height: 80%;
				width: 100%;
				flex-direction: column;
			}

			.input-container {
				width: 100%;
			}

			.search-input {
				width: 100%;
			}
		}

		.isHome {
			height: 100%;
			width: 95%;
			padding: 2% 0% 2% 0%;

			#inline-form-input-subject {
				margin-right: -7px!important;
			}

			form {
				width: 100%;
				flex-direction: column;
				justify-content: center;
				flex-wrap: nowrap;
				align-items: center;
				padding: 0 3%;
				margin-left: 0;
			}

			.input-container {
				margin-bottom: 7px;
				margin-left: -3%;
				width: 100%;
				padding: 0%;
			}

			.search-input {
				width: 100%;
			}

			.src-btn {
				margin-top: -3%;
				margin-bottom: 3%;
				width: 100%;
			}
		}

	}

	@media(max-width: 992px) {
		.filters {
			form {
				padding: 0 6%;
			}

			.section-title {
				margin: 0;
			}

			form {
				grid-template-columns: 1fr 1fr;
				grid-template-rows: repeat(5, 25% [row-start]);
				grid-row-gap: 10px;

				> div {

				}

				.input-container {
					max-width: 100%;
				}

				.src_title {
					grid-column: 1/3;
					grid-row: 1;
				}

				.src_subject, .src_tag {
					grid-row: 2;
				}

				.src_subject {
					grid-column: 1/2;
					justify-self: start;
				}

				.src_tag {
					grid-column: 2/3;
					justify-self: end;
				}

				.cbx-container {
					justify-content: flex-start;
					grid-column: 1;
					grid-row: 3;
				}

				.src-btn  {
					grid-column: 1/3;
					grid-row: 4;
					margin: 0;
					max-width: 180px;
				}

				.clear-container {
					grid-row: 4;
					grid-column: 2;

					.clear-btn {
						transform: translateX(70px);
					}
				}

			}

			.has-transcribus {
				grid-template-columns: 50% 50%;
				grid-template-rows: repeat(4, 35% [row-start]);
				grid-row-gap: 16px;
				margin-left: 0;
				margin-top: 20px;

				.src-btn {
					margin-left: 0;
					margin-top: -3%;
					grid-row: 3
				}
			}
		}

		.isHome {
			form {
				width: 90%;
				margin-top: 10px;
				margin-left: 0;

				.input-container {
					padding: 0;
					margin-left: 0;
				}

				.src-btn {
					margin-left: 0;
					margin-top: 3%;
					grid-row: 3
				}

			}

		}
		.filters .has-transcribus {
			margin-top: 1px;
		}
	}

	@media(max-width: 670px) {
		.filters {
			min-height: 350px;

			span {
				margin: 0;
			}

			form {
				flex-flow: nowrap;
				flex-direction: column;
				justify-content: space-between;
				padding: 0 6% 0 4%;
			}

			.search-input {
				margin: 1% 0;
			}

			button {
				margin-top: 20px;
			}

			.src-btn {
				width: 45%;
			}
		}

		.isHome {
			width: 100%;
			min-height: 280px;

			.src-btn {
				width: 36%;
				font-size: 13px;
			}

			.input-container {
			}

			.search-input {
				width: 100%;
			}
		}
	}

@media(max-width: 480px) {

	.filters {
		height: auto;
		padding-top: 7px;

		form {
			margin-top: 10px;
			grid-template-columns: 48% 50%;
			grid-template-rows: repeat(4, 30% [row-start]);
			grid-row-gap: 10px;
			padding: 0 4%;

			.clear-container {
				grid-row: 3;
			}
		}

		.has-transcribus {
			grid-template-rows: repeat(3, 30% [row-start])!important;
			grid-template-columns: 48% 50%;

			.src-btn {
				margin-top: 0%;
			}
		}

	}

	.min_height {
		min-height: 250px;
	}

	.isHome {
		width: 100%;

		form {
			margin-top: 10px;
			grid-template-columns: 50% 50%;
			grid-template-rows: repeat(4, 20% [row-start]);
			grid-row-gap: 10px;
		}

		.input-container {
			margin-left: -6%;
			width: 100%;
		}

		.src-btn {
			width: 50%;
		}
	}
}

@media(max-width: 480px) {

	.isHome {
		width: 100%;
		padding: 0;
    min-height: 207px;

		form {
			grid-template-rows: repeat(3, 30% [row-start]);

			.src-btn {
				margin-top: -2%;
			}

			.clear-container {
				grid-row: 3;
			}
		}
	}
}
</style>
