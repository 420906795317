import store from '../index'
import axios from 'axios'
import router from '@/router'
import Modifiers from '@/services/modifiers.js'

const collectionLink = `items?item_set_id=`

const	state = {
	collection: [],
	collectionItem: {},
	filter: {
		title: "",
		subject: "",
		tags: ""
	},
	collectionLoading: false,
	collectionPage: 1
}

const	actions = {
	loadCollection({ commit }) {
		let collectionUrl = `${store.getters.getUrl}${collectionLink}`
		let requestUrl = collectionUrl + router.currentRoute.params.subcollection + '&page=' + state.collectionPage + '&per_page=10&sort_by=dcterms:title'
		state.collectionLoading = true
		axios
			.get(requestUrl)
			.then((response) => {
				state.collectionLoading = false
				let formedItems = Modifiers.getItems(response.data)
				// console.log(formedItems)
				commit('updateCollection', formedItems)
			})
	},
	prevCollectionPage({ commit, dispatch }, state) {
		if (state.collectionPage > 1) {
			commit('goToPrev')
			dispatch('loadCollection')
		} else {
			commit('goToStart')
			dispatch('loadCollection')
		}
	},

	nextCollectionPage({ commit, dispatch }) {
		commit('goToNxt')
		dispatch('loadCollection')
	}
}

const	mutations = {
	updateCollection(state, newData) {
		state.collection = newData
	},
	goToStart(state) {
		state.collectionPage = 1
	},
	goToPrev(state) {
		state.collectionPage--
	},
	goToNxt(state) {
		state.collectionPage++
	}
}

const	getters = {
	getNewCollection: state => {
		return state.collection
	},

	isLoading: state => {
		return state.collectionLoading
	},

	currentPage: state => {
		return state.collectionPage
	}

}

export default {
	state,
	actions,
	mutations,
	getters
}
