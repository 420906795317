import store from '../index'
import axios from 'axios'
// import Modifiers from '@/services/modifiers.js'
import router from '../../router/index'

// eslint-disable-next-line no-unused-vars
const searchLink = `items?item_set_id=`

const state = {
	query: {
		title: "",
		subject: "",
		tag: ""
	},
	queryResults: [],
	resultIds: [],
	resultsPage: 1,
	totalRsltPages: 1,
	totalResutls: 1,
	totalEntries: 0,
	resultsLoading: false,
	searching: false,
	searchedTag: '',
	currentItemSet: {},
	isTagFr: false,
	hasTrans: false,
	error: ""
}

const actions = {
	searchItems({ commit }) {
		let baseUrl = `${store.getters.getUrl2}search?page=${state.resultsPage}&limit=${store.state.perPage}`
		let itemSet = state.currentItemSet.id ? `&item_set=${state.currentItemSet.id}` : ''
		let tag = state.query.tag ? `&tag=${state.query.tag}` : ''
		let title = state.query.title ? `&title=${state.query.title}` : ''
		let subject = state.query.subject ? `&subject=${state.query.subject}` : ''
		let transcribus = state.hasTrans ? `&transcribus=true` : `&transcribus=false`

		const finalUrl2 = baseUrl + itemSet + tag + title + subject + transcribus
		// console.log(finalUrl2)
		state.searching = true
		axios
			.get(finalUrl2)
			.then((results) => {
				state.searching = false
				// let formedItems = Modifiers.getItems(results.data)
				// console.log(results.data)
				commit('getResults', results.data)
				commit('setFRsearch', false)
			}).catch((error) => {
				state.searching = false
				state.error = error

				commit('getResults', { items: [], index: [] })
			})
	},

	applyItemSet({ commit }, set) {
		// console.log(set)
		commit('storeSet', set)
	},

	searchByTag({ commit }, tag) {
		commit('emptyQueries')
		let itemSetId = state.currentItemSet.id
		let tagUrl = `${store.getters.getUrl2}search?page=${state.resultsPage}&limit=${store.state.perPage}&item_set=${itemSetId}&tag=${tag}`
		state.searching = true
		commit('updateQueryTag', tag)
		axios
			.get(tagUrl).then((res) => {
				let formedItems = res.data
				// console.log(formedItems)
				state.searching = false
				commit('gotoSearchStart')
				commit('getResults', formedItems)
				if (router.app._route.name !== 'search') {
					router.push({ name: 'search' }).catch(err => {
						// console.log(err)
						state.error = err
					})
				}
			})
	},

	previousSearchPage({ commit, dispatch }) {
		if (state.resultsPage > 1) {
			commit('gotoPrevSrcPage')
			dispatch('searchItems')
		} else {
			commit('gotoSearchStart')
			dispatch('searchItems')
		}
	},
	nxtSearchPage({ commit, dispatch }) {
		commit('gotoNxtSrc')
		dispatch('searchItems')
	},

	srchTranscripts({ commit }) {
		commit('setTransMode', true)
	},

	clearSearchTag({ commit }) {
		commit('emptyTag')
	},

	clearTitle({ commit }) {
		commit('clearItemSet')
	},

	clearAll({ commit }) {
		commit('emptyQueries')
	},

	resetPage({ commit, dispatch }) {
		commit('gotoSearchStart')
		dispatch('searchItems')
	}
}

const mutations = {
	getResults(state, newData) {
		state.queryResults = newData.items
		state.resultIds = newData.index
		state.totalRsltPages = newData.pages
		state.totalEntries = newData.total
	},

	updateQueryTitle(state, newTitle) {
		state.query.title = newTitle
	},

	updateQuerySubject(state, newSubject) {
		state.query.subject = newSubject
	},

	updateQueryTag(state, newTag) {
		// let obj = objArray.find(obj => obj.id == 3);
		let srcTag = ''
		if (state.isTagFr) {
			const tagObj = store.state.tags.find(tag => tag.tag_fr === newTag)
			srcTag = tagObj ? tagObj.tag : ''
		}
		state.query.tag = srcTag.length > 0 ? srcTag : newTag
		// console.log("isFrench:", state.isTagFr)
	},

	setFRsearch(state, isFrench) {
		state.isTagFr = isFrench
	},

	setTransMode(state, newMode) {
		state.hasTrans = newMode
	},

	gotoSearchStart(state) {
		state.resultsPage = 1
	},

	gotoPrevSrcPage(state) {
		state.resultsPage--
	},

	gotoNxtSrc(state) {
		state.resultsPage++
	},

	emptyQueries(state) {
		state.query.title = ''
		state.query.subject = ''
		state.query.tag = ''
		state.isFrench = false
		state.hasTrans = false
	},

	clearItemSet(state) {
		state.currentItemSet = null
	},

	emptyTag(state) {
		state.searchedTag = ''
	},

	storeSet(state, newSet) {
		state.currentItemSet = newSet
	}

}

const getters = {
	getQueryResults(state) {
		return state.queryResults
	},

	getQueryTitle(state) {
		return state.query.title
	},

	getQuerySubject(state) {
		return state.query.subject
	},

	getQueryTag(state) {
		return state.query.tag
	},

	getSearchPage(state) {
		return state.resultsPage
	},

	getTotalPages(state) {
		return state.totalRsltPages
	},

	getTotalEntries(state) {
		return state.totalEntries
	},

	getSearchStatus(state) {
		return state.searching
	},
	getResultIds(state) {
		return state.resultIds
	},

	getCurrentSet(state) {
		return state.currentItemSet
	},

	searchFr(state) {
		return state.isTagFr
	},

	searchTrans(state) {
		return state.hasTrans
	},
	getStoredCollection(state) {
		if (state.currentItemSet) {
			let selection = store.state.allItemSets.filter(x => x.id === +state.currentItemSet.id)
			return selection[0]
		}
		return null
	}

}

export default {
	state,
	actions,
	mutations,
	getters
}
